import React from "react";
import { PageProps } from "gatsby";

import { pageTracker } from "../tracker";

export default function NotFoundPage(props: PageProps) {
  React.useEffect(() => {
    pageTracker({
      pageTitle: "404 Page Not Found",
      pageSubject: "Error Pages",
    });
  }, []);
  return <div>Not found</div>;
}
