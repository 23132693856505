import { FormDataErrorType } from "./components/Forms/FormsContext/types";

type trackerPayload = {
  pageTitle?: string;
  pageSubject?: string;
  event?: string;
  eventAction?: string;
  eventSubject?: string;
  eventLabel?: string;
  eventCategory?: string;
  userDesignation?: string;
  userSpecialty?: string;
};

export const tracker = (payload: trackerPayload) => {
  if (window) {
    // @ts-ignore
    window.dataLayer.push(payload);
  }
};

type eventTrackerPayload = {
  eventAction: string;
  eventLabel: string;
  eventCategory: string;
};

export const eventTracker = (payload: eventTrackerPayload): void => {
  tracker({ event: "gbt.event", eventSubject: "Meeting Request", ...payload });
};

type pageTrackerPayload = {
  pageTitle: string;
  pageSubject: string;
};

export const pageTracker = (payload: pageTrackerPayload): void => {
  tracker({
    event: "gbt.pageview",
    ...payload,
  });
};

export const formFieldTacker = (field, formData, page): void => {
  // Pushes tracking data whenever fields have anything entered, but only once.
  const isDesignation = field === "designation";
  const isSpecialty = field === "specialty";

  // Skip user designation and specialty – Tracked in respective components
  if (isSpecialty) return;
  if (isDesignation) return;

  const isOptInField = field === "optInMarketing";
  const fieldAlreadySet = formData[field];

  if (fieldAlreadySet || isOptInField) return;

  eventTracker({
    eventLabel: `Step ${page}/4 - ${decamelizeAndCapitalize(field)}`,
    eventAction: "Completed Field",
    eventCategory: "Request KOL Form",
  });
};

export const formErrorTracker = (errors: FormDataErrorType[], page: number) => {
  const cleanErrors = errors.map((error) => {
    return decamelizeAndCapitalize(error.path[0]);
  });

  eventTracker({
    eventCategory: "Request KOL Form",
    eventLabel: `Step ${page}/4 - Invalid Form`,
    eventAction: "Triggered Error",
  });

  cleanErrors.forEach((err) => {
    eventTracker({
      eventCategory: "Request KOL Form",
      eventLabel: `Step ${page}/4 - Invalid ${err}`,
      eventAction: "Triggered Error",
    });
  });
};

export const formSubmitTracker = (formData): void => {
  const trackedSubmissionFields = [
    {
      fieldName: "presentationTopic",
      eventAction: "Selected Presentation",
    },
    {
      fieldName: "optInMarketing",
      eventAction: "Selected Marketing Opt-In",
    },
    {
      fieldName: "zip",
      eventAction: "Entered Zip Code",
    },
    {
      fieldName: "hasPreferredExpert",
      eventAction: "Selected Preferred Expert",
    },
    {
      fieldName: "preferredExpert",
      eventAction: "Selected Preferred Expert Name",
    },
    {
      fieldName: "alternateExpert",
      eventAction: "Selected Alternate Expert Name",
    },
    {
      fieldName: "specialty",
      eventAction: "Selected Specialty",
    },
    {
      fieldName: "designation",
      eventAction: "Selected Designation",
    },
  ];

  trackedSubmissionFields.forEach((field) => {
    const eventActionVal = field.eventAction;
    const fieldName = field.fieldName;

    let eventLabelVal = formData[fieldName];

    // Removes default HCP option if a preferred one is not selected
    if (eventLabelVal === "none") return;

    // Modifies form outputs for GTM tracking
    if (fieldName === "hasPreferredExpert" || fieldName === "optInMarketing") {
      eventLabelVal = eventLabelVal === "true" ? "Yes" : "No";
    }

    eventTracker({
      eventLabel: eventLabelVal,
      eventAction: eventActionVal,
      eventCategory: "Request KOL Form",
    });
  });
};

function decamelizeAndCapitalize(str, separator = " ") {
  return (
    str
      .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
      .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
      .toLowerCase()
      // Capitolizes each word in string
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  );
}
